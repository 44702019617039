import {GatsbyImage} from 'gatsby-plugin-image'
import Layout from "../components/layout"
import Post from '../components/blogPost'
import Seo from "../components/seo"
import { graphql } from "gatsby"
import React from 'react'

const BlogPost = ({data}) =>{
    const { postsNodes: { nodes: posts }} = data
    const morePosts = posts.map(({name, featureImage, date}) => { 
        return ( 
            <Post title={name} key={name} team={false} date={date} image={featureImage} />
        )
    })
    return (
        <Layout>
            <Seo description={data.post?.metaDescription} title={data.post?.metaTitle} />
            <div className="spacer half"></div>
            <div className="title-area p20 flex max-1000 ma m-wrap">
                <div className="w-50 m-100">
                    <div className="author flex align-center">
                        <div className="icon mr20">
                            <div className="profile-icon">
                                <GatsbyImage image={data.post.author?.authorImage?.gatsbyImageData} alt={data.post.author?.name} className="bg-image" /> 
                            </div>
                        </div>
                        <div className="information">
                            <p className="m0 uppercase">{data.post.author?.name}</p>
                            <p className="m0 text-grey">{data.post.author?.companyPosition}</p>
                        </div>
                    </div>
                </div>
                <div className="w-50 m-100 m-mt40"> 
                    <div className="max-500">
                        <h2 className="m0">{data.post?.name}</h2>
                    </div>
                </div>
            </div>
            <div className="spacer half"></div>
            <div className="feature--image pos-rel">
                <div className="max-1000 ma pos-rel">
                    <div className="ratio-1-2">
                        <GatsbyImage image={data.post.featureImage?.gatsbyImageData} alt={data.post?.name} className="bg-image" /> 
                    </div>
                </div>
                <div className="background--half" style={{backgroundColor:'#ECECEC'}}></div>
            </div>
            
            <div className="p20" style={{backgroundColor:'#ECECEC'}}>
                <div className="max-600 mt40 mb40 ma prewrap sainte" dangerouslySetInnerHTML={{__html:data.post?.content}}>
                </div>
            </div>
            <div className="related--posts">
                <div className="spacer half"></div>
                <div className="title-area p20">
                    <p className="h2 m0">More</p>
                </div>
                <div className="posts--grid grid gap-v-60 m-p20">
                    {morePosts}
                </div>
                <div className="spacer half m-hide"></div>
            </div>
        </Layout>
    )
}
export default BlogPost


export const pageQuery = graphql`
    query blog($name: String!){
        post:datoCmsBlogPost(name: {eq: $name}){
            name
            metaTitle
            metaDescription
            content 
            author {
                name
                companyPosition
                authorImage {
                    gatsbyImageData
                }
            }
            featureImage {
                gatsbyImageData
            }
        } 
        postsNodes: allDatoCmsBlogPost(filter: {name: {ne: $name}}, limit: 3)  {
            nodes {
                name
                featureImage {
                    gatsbyImageData
                }
                date(formatString: "D M Y")
            }
        }
    }
`
