import React from 'react'
import { Link } from "gatsby"
import {GatsbyImage} from 'gatsby-plugin-image'
import Arrow from "../components/partials/arrow"  
import _ from 'lodash'

const BlogPost = ({image, title, date,companyPosition,team = false}) =>{ 
    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var formattedDate = date?.split(' ')[0]+' '+months[date?.split(' ')[1]-1]+' '+date?.split(' ')[2];
    return(
    <Link className="link blog-post--single m-mb20" to={( !team ? `/blog/${_.kebabCase(title)}` : `/team/${_.kebabCase(title)}` )}>  
        <div className="internal-container p20 mp0 flex flex-wrap">
            <div className="content w-100">
                <div className="image-wrapper">
                    <div className="ratio-1-2 pos-rel">
                        <GatsbyImage image={image?.gatsbyImageData} objectPosition={(team ? "center 10%"  : "")} alt={title} className="bg-image" /> 
                    </div>
                </div>
                {(!team ? <><p className="m0 text-light mt20 uppercase">{formattedDate}</p> <p className="sainte">{title}</p></>: null)}
                {team ? <><h3 className=" uppercase m0 mt20 mb15 normal">{title}</h3> <p className="m0 uppercase">{companyPosition}</p></> : null}
            </div>
            <div className="read-more flex space-between align-center align-self--bottom mt40 w-100">
                Read More <Arrow />
            </div>
        </div>
    </Link>
    )
}
export default BlogPost